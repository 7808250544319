<template>
  <!-- BEGIN: Display Information -->
  <div class="intro-y box lg:mt-5">
    <div
      class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="font-medium text-base mr-auto">
        Display Information
      </h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-12 xl:col-span-4">
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            <div
              class="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto"
            >
              <img
                class="rounded-md"
                rel="preload"
                alt="Quant Lines"
                :src="currentUser.profile_photo"
              >
              <div
                title="Remove this profile photo?"
                class="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-green-600 right-0 top-0 -mr-2 -mt-2"
              >
                <i
                  data-feather="x"
                  class="w-4 h-4"
                />
              </div>
            </div>
            <div class="w-40 mx-auto cursor-pointer relative mt-5">
              <base-button
                class="w-full"
                :loading="loadingProfilePhoto"
              >
                Change photo
              </base-button>
              <input
                ref="profile_photo"
                type="file"
                class="w-full h-full top-0 left-0 absolute opacity-0"
                @change="changeProfilePhoto"
              >
              <span
                v-if="errorHas('profile_photo', 'photoI')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("profile_photo", "photoI") }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-8">
          <div>
            <label>Display Name</label>
            <input
              type="text"
              :value="currentUser.first_name + ' ' + currentUser.last_name"
              class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
              disabled
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Display Information -->
  <!-- BEGIN: Personal Information -->
  <div class="intro-y box lg:mt-5">
    <div
      class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="font-medium text-base mr-auto">
        Personal Information
      </h2>
    </div>
    <div class="p-5">
      <form
        action=""
        method="POST"
        @submit.prevent="savePersonalInformation"
      >
        <div class="grid grid-cols-12 gap-5">
          <div class="col-span-12 xl:col-span-6">
            <div class="mt-3">
              <label
                :class="{ 'has-error': errorHas('first_name', 'personalI') }"
              >First name</label>
              <input
                v-model="personalInformation.first_name"
                type="text"
                class="input w-full border mt-2"
                placeholder="First name"
              >
              <span
                v-if="errorHas('first_name', 'personalI')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("first_name", "personalI") }}
              </span>
            </div>
            <div class="mt-3">
              <label
                :class="{ 'has-error': errorHas('last_name', 'personalI') }"
              >Last name</label>
              <input
                v-model="personalInformation.last_name"
                type="text"
                class="input w-full border mt-2"
                placeholder="Last name"
              >
              <span
                v-if="errorHas('last_name', 'personalI')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("last_name", "personalI") }}
              </span>
            </div>
            <div class="mt-3">
              <label
                :class="{ 'has-error': errorHas('middle_name', 'personalI') }"
              >Middle name</label>
              <input
                v-model="personalInformation.middle_name"
                type="text"
                class="input w-full border mt-2"
                placeholder="Middle name"
              >
              <span
                v-if="errorHas('middle_name', 'personalI')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("middle_name", "personalI") }}
              </span>
            </div>
          </div>
          <div class="col-span-12 xl:col-span-6">
            <div class="mt-3">
              <label :class="{ 'has-error': errorHas('gender', 'personalI') }">
                Gender
                <select
                  v-model="personalInformation.gender"
                  class="input w-full mt-2"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <span
                  v-if="errorHas('gender', 'personalI')"
                  class="pristine-error text-theme-6 mt-2"
                >
                  {{ errorGet("gender", "personalI") }}
                </span>
              </label>
            </div>
            <div class="mt-3">
              <label>Email</label>
              <input
                type="text"
                :value="currentUser.email"
                class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                disabled
              >
            </div>
            <div class="mt-3">
              <label
                :class="{ 'has-error': errorHas('phone_number', 'personalI') }"
              >Phone Number</label>
              <input
                v-model="personalInformation.phone_number"
                type="text"
                class="input w-full border mt-2"
                placeholder="Phone number"
              >
              <span
                v-if="errorHas('phone_number', 'personalI')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("phone_number", "personalI") }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <base-button
            icon="ri-save-line mr-1"
            class="w-20"
            :loading="loadingPersonalInformation"
            @click.prevent="savePersonalInformation"
          >
            Save
          </base-button>
        </div>
      </form>
    </div>
  </div>
  <!-- END: Personal Information -->
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { useErrors } from "@/compositions/useErrors";

export default defineComponent({
  name: "PersonalInformation",
  setup() {
    const errors = useErrors();
    errors.errorInitContainers(["photoI", "displayI", "personalI"]);
    return {
      ...errors
    };
  },
  data() {
    return {
      loadingProfilePhoto: false,
      profile_photo: null,
      loadingDisplayInformation: false,
      errorsDisplayInformation: [],
      displayInformation: {},
      loadingPersonalInformation: false,
      errorsPersonalInformation: [],
      personalInformation: {
        first_name: "",
        last_name: "",
        middle_name: "",
        gender: "",
        email: "",
        phone_number: ""
      }
    };
  },
  computed: {
    ...mapGetters("profile", ["currentUser"]),
    ...mapGetters("skill", ["skillList"])
  },
  async created() {
    await this.getAuthUser().then(({ data }) => {
      this.personalInformation = data;
    });
  },
  methods: {
    ...mapActions("profile", [
      "getAuthUser",
      "updateDisplayInformation",
      "updatePersonalInformation",
      "updateProfilePhoto"
    ]),
    ...mapActions("skill", ["getSkillList"]),
    async saveDisplayInformation() {
      this.errorSet({}, "displayI");
      this.loadingDisplayInformation = true;
      try {
        await this.updateDisplayInformation(this.displayInformation);
        this.loadingDisplayInformation = false;
      } catch (e) {
        this.errorSet(e.errors, "displayI");
        this.loadingDisplayInformation = false;
      }
    },
    async savePersonalInformation() {
      this.errorSet({}, "personalI");
      this.loadingPersonalInformation = true;
      try {
        await this.updatePersonalInformation(this.personalInformation);
        this.loadingPersonalInformation = false;
      } catch (e) {
        this.errorSet(e.errors, "personalI");
        this.loadingPersonalInformation = false;
      }
    },
    async changeProfilePhoto(event) {
      this.errorSet({}, "photoI");
      const formData = new FormData();
      formData.append("profile_photo", event.target.files[0]);
      this.loadingProfilePhoto = true;
      try {
        await this.updateProfilePhoto(formData);
        this.loadingProfilePhoto = false;
      } catch (e) {
        this.errorSet(e.errors, "photoI");
        this.loadingProfilePhoto = false;
      }
    },
  }
});
</script>

<style scoped></style>
